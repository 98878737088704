import React, { useEffect, useRef, useState } from "react";
import uninsatllImg from "../../assets/images/home/ocr-uninstall-image.png";
import logoImg from "../../assets/images/home/ocr-logo.png";
import { Link } from "react-router-dom";
import AdSenseAd from "../../components/Adsense/adSenseAd";
import FeedBackForm from "./components/FeedBackForm";

const Unintall = () => {
  // get dynamically heigth and width for adsense
  const [adSenseEle, setAdSenseEle] = useState({ height: '100px', width: '500px' });
  const adSenseRef = useRef();
  useEffect(() => {
    if (adSenseRef.current) {
      const { clientWidth, clientHeight } = adSenseRef.current;
      setAdSenseEle({
        height: `${clientHeight}px`,
        width: `${clientWidth}px`
      })
    }
  }, [adSenseRef.current]);

  return (
    <div className="uninstall-page" id="root">
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid justify-content-center">
                <Link className="navbar-brand" to="/">
                  <img src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
          <div className="adsense-outer container-fluid">
            <div ref={adSenseRef} className="adsense-bar adsense-left-bar">
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="7560981818"
                format="vertical"
                style={{ display: 'block', minWidth: "300px", width: adSenseEle.width, height: adSenseEle.height }}
                responsive={true}
              />
            </div>
            <div className="adsense-bar adsense-right-bar">
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="7560981818"
                format="vertical"
                style={{ display: 'block', minWidth: "300px", width: adSenseEle.width, height: adSenseEle.height }}
                responsive={true}
              />
            </div>
            <div className="row">
              <div className="star_emoji col-sm-6 mx-auto pt-3">
                <span className="thankEmoji">
                  <span className="emoji">
                    <img src={uninsatllImg} />
                    <span className="emoji_content">
                      <div className="section_title">
                        <h2>
                          Capios{" "}
                          <span className="heading-badge-text">
                            successfully uninstalled
                          </span>{" "}
                          from your device now!
                        </h2>
                        <p>
                          Your experience matters to us. Would you mind taking
                          few minutes to share your feedback on our product?
                        </p>
                      </div>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feedback-section screen_recording_game">
        <div className="row m-0">
          <FeedBackForm />
        </div>
      </div>
    </div>
  );
};

export default Unintall;
