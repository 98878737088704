import React, { useEffect, useState } from 'react';
import mainLogoImg from '../../assets/images/home/ocr-footer-logo.png';
import AdSenseAd from '../../components/Adsense/adSenseAd';
import { useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { manageScreenshotDetails } from '../../redux/actions/manageScreenshots';
import { API_URL, localStoragePrefix } from '../../utils/config';

const Screenshot = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [screenshotDetails, setScreenshotDetails] = useState();
    const userID = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
    const authToken = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
    const itemPath = screenshotDetails?.path && API_URL + screenshotDetails?.path;

    useEffect(() => {
        if (!id) return;

        const payload = {
            user_id: userID,
            authToken: authToken,
            id: id
        }

        dispatch(manageScreenshotDetails(payload, (type, res) => {
            if (type === 'success') {
                setScreenshotDetails(res);
            }
        }));
    }, [id]);

    return (
        <div className='quixy-share-page-wrapper' id="quixy-share-page-wrapper">
            <div className='quixy-header' id='quix-dashboard-header'>
                <div className='quix-dashboard-logo'>
                    <img src={mainLogoImg} alt="logo" />
                </div>
            </div>
            <div className='quix-share-page-content'>
                <div className='quix-share-page-content-inner'>
                    <div className='adsense-left-sidebar adsense-sidebar'>
                        <AdSenseAd
                            client="ca-pub-4424794709901498"
                            slot="2002881847"
                            format="vertical"
                            style={{ display: 'block', minWidth: "100%", width: "100%", height: "100%" }}
                            responsive={true}
                        />
                    </div>
                    <div className='quix-share-content'>
                        <div className='quix-share-item'>
                            <img src={itemPath} />
                        </div>
                    </div>
                    <div className='adsense-right-sidebar adsense-sidebar'>
                        <AdSenseAd
                            client="ca-pub-4424794709901498"
                            slot="8461586205"
                            format="vertical"
                            style={{ display: 'block', minWidth: "100%", width: "100%", height: "100%" }}
                            responsive={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Screenshot;