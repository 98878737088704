import axios from "axios";
import { API_URL } from "../../utils/config";

export const getTwitterRequest = async (redirect_Url, callback) => {
    try {
        let data = JSON.stringify({
            REDIRECT_URI: redirect_Url,
        });

        let config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const res = await axios.post(
            `${API_URL}/share/video/req-token-twitter`,
            data,
            config
        );

        if (res?.data?.status) {
            callback("success", res?.data);
        }
    } catch (error) {
        callback("failed", error);
    }
};

export const getTwitterLogin = async (payload, callback) => {
    try {
        let data = JSON.stringify({
            oauthToken: payload?.oauthToken,
            oauthVerifier: payload?.oauthVerifier,
        });
        let config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const res = await axios.post(
            `${API_URL}/share/video/twitter-login`,
            data,
            config
        );

        if (res?.data?.status) {
            callback("success", res?.data);
        }
    } catch (error) {
        callback("failed", error);
    }
};

export const shareOnTwitter = (payload, callback) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                accessToken: payload?.accessToken,
                accessSecret: payload?.accessSecret,
                userId: payload?.userId,
                mediaUrl: payload?.mediaUrl,
                mediaType: payload?.mediaType,
                text: payload?.text,
            });

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/integration/twitter-upload`,
                data,
                config
            );
            if (res?.data?.status) {
                callback("success", res?.data);
            }
        } catch (error) {
            const message = error?.response?.message;
            console.log(error, "---error---");
            callback("failed", message);
        }
    };
};

export const shareOnLinkedin = (payload, callback) => {
    return async (dispatch) => {
        try {
            let data = new FormData();
            data.append("file", payload?.file);
            data.append("access_token", payload?.accessToken);
            data.append("text", payload?.text);
            data.append("description", payload?.description);
            data.append("media_type", payload?.mediaType);
            let config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };

            const res = await axios.post(
                `${API_URL}/integration/upload-linkdin`,
                data,
                config
            );
            if (res?.data?.status) {
                callback("success", res?.data);
            }
        } catch (error) {
            const message = error?.response?.message;
            console.log(error, "---error---");
            callback("failed", message);
        }
    };
};

export const getNotionLogin = async (code, callback) => {
    try {
        const res = await axios.post(`${API_URL}/integration/notion-login/${code}`);
        if (res?.data?.status) {
            callback("success", res);
        }
    } catch (error) {
        callback("failed", error);
    }
};

export const shareOnNotion = (payload, callback) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                accessToken: payload?.accessToken,
                databaseId: payload?.databaseId,
                taskData: {
                    title: payload?.title,
                    description: payload?.description,
                },
                mediaUrl: payload?.mediaUrl,
                mediaType: payload?.mediaType,
            });

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/integration/upload-notion`,
                data,
                config
            );
            if (res?.data?.status) {
                callback("success", res);
            }
        } catch (error) {
            callback("failed", error);
        }
    };
};

export const getDropBoxLogin = async (code, callback) => {
    try {
        const res = await axios.post(
            `${API_URL}/integration/dropbox-login/${code}`
        );
        if (res?.data?.status) {
            callback("success", res);
        }
    } catch (error) {
        callback("failed", error);
    }
};

export const shareOnDropBox = (payload, callback) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                token: payload?.accessToken,
                recording: payload?.mediaUrl,
            });

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/integration/upload-dropbox`,
                data,
                config
            );
            if (res?.data?.status) {
                callback("success", res);
            }
        } catch (error) {
            callback("failed", error);
        }
    };
};

export const getSlackLogin = async (code, callback) => {
    try {
        let data = JSON.stringify({
            code: code,
        });

        let config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const res = await axios.post(
            `${API_URL}/integration/slack-login`,
            data,
            config
        );

        if (res?.data?.status) {
            callback("success", res);
        }
    } catch (error) {
        callback("failed", error);
    }
};

export const shareOnSlack = (payload, callback) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                access_token: payload?.access_token,
                channel: payload?.channel,
                text: payload?.text,
                attachments: [
                    {
                        image_url: payload?.image_url,
                    },
                ],
            });

            let config = {
                headers: {
                    "Content-type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/integration/upload-slack`,
                data,
                config
            );

            if (res?.data?.status) {
                callback("success", res);
            }
        } catch (error) {
            callback("failed", error);
        }
    };
};

export const getEvenoteRequest = async (redirect_Url, callback) => {
    try {
        let data = JSON.stringify({
            callbackUrl: redirect_Url,
        });

        let config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const res = await axios.post(
            `${API_URL}/integration/req-token-evenote`,
            data,
            config
        );

        if (res) {
            callback("success", res?.data);
        }
    } catch (error) {
        callback("failed", error);
    }
};

export const getEvernoteLogin = async (payload, callback) => {
    try {
        let data = JSON.stringify({
            oauth_token: payload?.oauthToken,
            oauth_verifier: payload?.oauthVerifier,
            requestTokenSecret: payload?.requestTokenSecret,
        });
        let config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await axios.post(
            `${API_URL}/integration/evernote/req-access-token`,
            data,
            config
        );
        if (res) {
            callback("success", res?.data);
        }
    } catch (error) {
        callback("failed", error);
    }
};

// upload social media
export const shareOnSocialMedia = (payload, callback) => {
    return async (dispatch) => {
        try {
            let data = JSON.stringify({
                uploadType: payload?.uploadType,
                mediaType: payload?.mediaType,
                authObject: payload?.authObject,
                ...(payload?.mediaType === "image" ? { sid: payload?.id } : {}),
                ...(payload?.mediaType === "video" ? { vid: payload?.id } : {}),
            });

            let config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/integration/share`,
                data,
                config
            );
            if (res) {
                callback("success", res?.data);
            }
        } catch (error) {
            const message = error?.response?.data;
            callback("failed", message);
        }
    };
};

export const getMicrosoftLogin = async (payload, callback) => {
    try {
        let data = JSON.stringify({
            code: payload?.code,
            type: payload?.type,
        });

        let config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        const res = await axios.post(
            `${API_URL}/integration/onenote-req-access-token`,
            data,
            config
        );
        if (res) {
            callback("success", res?.data);
        }
    } catch (error) {
        callback("failed", error);
    }
};
