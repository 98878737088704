import React, { useEffect, useState, useCallback } from "react";
import Layout from "../../layout/Layout";
import { connect, useDispatch } from "react-redux";
import { API_URL, localStoragePrefix } from "../../utils/config";
import { getAllSavedSnippets } from "../../redux/actions/manageSavedSnippets";
import Loader from "../../components/Loader/Loader";
import viewFileIcon from "../../assets/images/view-file-icon.png";
import playIcon from "../../assets/images/play-black-icon.png";
import pauseIcon from "../../assets/images/pause-black-icon.png";
import { checkServerURL } from "../../helper/helper";

const SavedSnippets = ({
    snippetsDetails,
    loadingSnippets,
    notFoundSnippets,
}) => {
    // Get local storage user id & access token
    const userID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const accessToken = localStorage.getItem(
        localStoragePrefix + "screenGeniusAuthToken"
    );
    const dispatch = useDispatch();
    const [playSnippetsAudio, setPlaySnippetsAudio] = useState(false);
    // Get all user saved snippets list
    useEffect(() => {
        if (userID && accessToken) {
            const payload = {
                user_id: Number(userID),
                access_token: accessToken,
              };
            dispatch(getAllSavedSnippets(payload));
        }
    }, [userID, accessToken]);
    // saved snippets list data
    const snippetsData = snippetsDetails?.data && snippetsDetails?.data;

    const [currentAudio, setCurrentAudio] = useState(null);
    const [currentAudioIndex, setCurrentAudioIndex] = useState(-1);
    const [isPlaying, setIsPlaying] = useState(false);
    // manage snippet audio
    const handlePlayPause = useCallback(
        (index, item) => {
            const audioURL = item?.audio ? checkServerURL(API_URL, item.audio) : null;
            if (!audioURL) {
                console.error("No audio URL provided");
                return;
            }
            // reset audio time and button status
            const resetAudio = (audio) => {
                audio.currentTime = 0;
                setIsPlaying(false);
            };
            if (index !== currentAudioIndex) {
                if (currentAudio) {
                    currentAudio.pause();
                    currentAudio.currentTime = 0;
                }
                const newAudio = new Audio(audioURL);
                newAudio.play();
                setCurrentAudio(newAudio);
                setCurrentAudioIndex(index);
                setIsPlaying(true);
                newAudio.onended = () => resetAudio(newAudio);
            } else {
                if (isPlaying) {
                    currentAudio.pause();
                    currentAudio.currentTime = 0;
                    currentAudio.onended = () => resetAudio(currentAudio);
                } else {
                    currentAudio.play();
                    currentAudio.onended = () => resetAudio(currentAudio);
                }
                setIsPlaying(!isPlaying);
            }
        },
        [currentAudio, currentAudioIndex, isPlaying]
    );

    return (
        <Layout page={"ocr-snippets"}>
            <div id="quix-dashboard-content">
                <div className="saved-snippets-content">
                    <div className="card saved-snippets-card">
                        <div className="card-header">
                            <h5 className="table-title m-0 py-1">Saved Snippets</h5>
                        </div>
                        <div className="card-body">
                            <table className="table saved-snippets-table">
                                <thead>
                                    <tr>
                                        <th>Sr no</th>
                                        <th>Text</th>
                                        <th>Type</th>
                                        <th>View Snippet</th>
                                        <th>Listen Audio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loadingSnippets ? (
                                        <tr>
                                            <td colSpan="4">
                                                <div className="quix-data-loading text-start mt-3">
                                                    <Loader />
                                                </div>
                                            </td>
                                        </tr>
                                    ) : !notFoundSnippets && snippetsData ? (
                                        snippetsData &&
                                        snippetsData?.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        {item?.text ? (
                                                            <div
                                                                dangerouslySetInnerHTML={{ __html: item?.text }}
                                                            />
                                                        ) : (
                                                            "N/A"
                                                        )}
                                                    </td>
                                                    <td>{item?.type ? item?.type : "N/A"}</td>
                                                    <td>
                                                        {item?.file ? (
                                                            <a
                                                                href={checkServerURL(API_URL, item?.file)}
                                                                target="_blank"
                                                                className=""
                                                            >
                                                                <img
                                                                    className="view-snippet-file"
                                                                    src={viewFileIcon}
                                                                />
                                                            </a>
                                                        ) : (
                                                            "N/A"
                                                        )}
                                                    </td>
                                                    <td>
                                                        {item?.audio ? (
                                                            <img
                                                                className={`view-snippet-audio`}
                                                                src={
                                                                    index === currentAudioIndex && isPlaying
                                                                        ? pauseIcon
                                                                        : playIcon
                                                                }
                                                                id={`snippet-${index}`}
                                                                onClick={() => handlePlayPause(index, item)}
                                                                title={
                                                                    index === currentAudioIndex && isPlaying
                                                                        ? "Pause audio"
                                                                        : "Play audio"
                                                                }
                                                            />
                                                        ) : (
                                                            "N/A"
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan="4">
                                                <div className="px-4 pt-3 pb-1 no-records">
                                                    {" "}
                                                    No Records Found!
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = (state) => ({
    snippetsDetails: state?.saved_snippets?.list,
    loadingSnippets: state?.saved_snippets?.is_loading,
    notFoundSnippets: state?.saved_snippets?.not_found,
});

export default connect(mapStateToProps, { getAllSavedSnippets })(SavedSnippets);
