import React from 'react';

const StatusCard = ({ status, message, statusClass }) => {
    return (
        <div className={`card status-card ${statusClass}`}>
            <div className="card-body">
                <h5 className="card-title">{status}</h5>
                <p className="card-text">{message}</p>
            </div>
        </div>
    );
};

export default StatusCard;