import {
    LOADING_OCR_REPORT,
    OCR_REPORT,
    NOT_FOUND_OCR_REPORT,
} from "../../actions/type";

const INITIAL_STATE = {
    ocr_report: [{}],
    ocr_report_loading: false,
    ocr_report_not_found: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_OCR_REPORT:
            return {
                ...state,
                ocr_report_loading: true,
                ocr_report_not_found: false,
            };
        case OCR_REPORT:
            return {
                ...state,
                ocr_report: action.payload,
                ocr_report_loading: false,
                ocr_report_not_found: false,
            };
        case NOT_FOUND_OCR_REPORT:
            return {
                ...state,
                ocr_report: [],
                ocr_report_loading: false,
                ocr_report_not_found: true,
            };
        default:
            return state;
    }
};
