import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logoImg from "../../../assets/images/home/ocr-logo.png";
import googleIcon from "../../../assets/images/google-icon.png";
import faceBookIcon from "../../../assets/images/facebook-icon.png";
import linkedInIcon from "../../../assets/images/linkedin-icon.png";
import twitterIcon from "../../../assets/images/twitter-x-icon.png";
import showPassIcon from "../../../assets/images/eye-pass-icon.png";
import hidePassIcon from "../../../assets/images/hidden-pass-icon.png";
import loaderIcon from "../../../assets/images/light-loader.gif";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  loginUser,
  createUserAccount,
} from "../../../redux/actions/authentication";
import { failureMessagePopup, redirectURL } from "../../../helper/helper";
import { signUpSchema } from "./Validation";
import SuccessVerifyModal from "../../../components/Modals/SuccessVerifyModal";
import { getGeneralSettingcredentials } from "../../../redux/actions/configServices";
import { WEB_URL } from "../../../utils/config";
import TwitterLogin from "../components/SocialLogin/TwitterLogin";
import LinkedInLogin from "../components/SocialLogin/LinkedInLogin";
import AdSenseAd from "../../../components/Adsense/adSenseAd";

const SignUp = () => {
  const dispatch = useDispatch();
  const [termCondition, setTermCondition] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [formErrors, setFormErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [configObj, setConfigObj] = useState({});
  const [isSignUploading, setIsSignUploading] = useState(false);

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    getGeneralSettingcredentials((res) => {
      const googleDetails = res?.google && res?.google;
      const facebookDetails = res?.facebook && res?.facebook;
      const linkedinDetails = res?.linkedin && res?.linkedin;
      const twitterDetails = res?.twitter && res?.twitter;
      setConfigObj({
        google: googleDetails,
        facebook: facebookDetails,
        linkedin: linkedinDetails,
        twitter: twitterDetails,
      });
    });
  }, []);

  const handleSubmit = () => {
    if (signUpSchema({ formData, setFormErrors }) && termCondition) {
      setIsSignUploading(true);
      setIsDisabled(true);
      dispatch(
        createUserAccount(formData, (type, res) => {
          if (type === "success") {
            setIsSignUploading(false);
            setMessage(res?.message);
            setIsDisabled(false);
          } else if (type === "failed") {
            failureMessagePopup("Error Message", res?.message);
            setIsSignUploading(false);
            setIsDisabled(false);
          }
        })
      );
    }
  };

  const manageSocialResponse = (response) => {
    let userData = null;
    if (response?.provider === "google" && response?.profile) {
      const { email, name, picture } = response?.profile;
      const payload = {
        name: name,
        email: email,
        picture: picture,
      };
      if (email && name) {
        userData = payload;
      }
    } else if (response?.provider === "facebook") {
      const { email, name } = response?.profile;
      const profileUrl = response?.profile?.picture?.data?.url;
      const payload = {
        name: name,
        email: email,
        picture: profileUrl,
      };
      if (email && name) {
        userData = payload;
      }
    }

    if (userData !== null) {
      dispatch(
        loginUser(userData, (type, res) => {
          if (type === "success") {
            window.location.href = "/dashboard";
          }
        })
      );
    }
  };

  // twitter login
  const twitterCallBack = (type, res) => {
    if (type === "success") {
      const payload = {
        name: res?.data?.name,
        email: res?.data?.email,
        picture: res?.data?.picture,
      };
      dispatch(
        loginUser(payload, (type, res) => {
          if (type === "success") {
            setLoading(false);
            return (window.location.href = redirectURL());
          } else if (type === "failed") {
            failureMessagePopup("Error Message", res?.message);
            setLoading(false);
          }
        })
      );
    } else if (type === "failed") {
      failureMessagePopup("Error Message", "Something went wrong!");
    }
  };

  // linkedin login
  const linkedinCallBack = (type, res) => {
    if (type === "success") {
      const payload = {
        name: res?.data?.name,
        email: res?.data?.email,
        picture: res?.data?.picture,
      };
      dispatch(
        loginUser(payload, (type, res) => {
          if (type === "success") {
            setLoading(false);
            return (window.location.href = redirectURL());
          } else if (type === "failed") {
            failureMessagePopup("Error Message", res?.message);
            setLoading(false);
          }
        })
      );
    } else if (type === "failed") {
      failureMessagePopup("Error Message", res?.message);
    }
  };

  const handleGoLoginPage = (e) => {
    navigate("/login");
  };

  return (
    <div className="login-page ">
      <div id="quix-dashboard-wrapper" className="postion-relative">
        {isSignUploading && (
          <div className="loader-box">
            <img src={loaderIcon} />
          </div>
        )}
        <div id="quix-dashboard-inner">
          <div className="login-logo-outer">
            <div className="login-logo-inner">
              <Link to={"/"}>
                <img src={logoImg} />
              </Link>
            </div>
          </div>
          <div className="login-outer-box">
            <div className="adsense-box">
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="3435754143"
                format="vertical"
                style={{ display: 'block', minWidth: "300px", maxWidth: "400px", width: "100%", height: "100%" }}
                responsive={true}
              />
            </div>
            {/* form outer */}
            <div className="login-form-outer">
              <div className="login-form-inner">
                <span className="form-title">Join Capios Today!</span>
                <span className="form-subtitle">
                  To get started, please sign up
                </span>
              </div>
              <div className="login-with-details">
                <div className="custom-login-details">
                  <div className="custom-details-form card">
                    <div className="card-body">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <div className="quix-edit-name">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter your name"
                            onChange={(e) =>
                              setFormData({ ...formData, name: e.target.value })
                            }
                            defaultValue={formData?.name}
                            maxLength={255}
                          />
                        </div>
                        {formErrors?.nameError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.nameError}
                          </p>
                        )}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <div className="quix-edit-email">
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            placeholder="Enter your email"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                            defaultValue={formData?.email}
                            maxLength={255}
                          />
                        </div>
                        {formErrors?.emailError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.emailError}
                          </p>
                        )}
                      </div>
                      <div className="mb-3 user-password-filed">
                        <label htmlFor="password" className="form-label">
                          Password
                          <span className="text-danger ps-1">*</span>
                        </label>
                        <div className="password-eyes quix-edit-password">
                          <input
                            type={!showPassword ? "password" : "text"}
                            className="form-control"
                            id="password"
                            placeholder="Enter your password"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              })
                            }
                            defaultValue={formData?.password}
                            maxLength={255}
                          />
                          <img
                            src={!showPassword ? hidePassIcon : showPassIcon}
                            height={"100%"}
                            width={"100%"}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </div>
                        {formErrors?.passwordError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.passwordError}
                          </p>
                        )}
                      </div>
                      <div className="mb-3 user-password-filed">
                        <label htmlFor="confirm-password" className="form-label">
                          Confirm Password
                          <span className="text-danger ps-1">*</span>
                        </label>
                        <div className="password-eyes quix-edit-password">
                          <input
                            type={!showConfirmPassword ? "password" : "text"}
                            className="form-control"
                            id="confirm-password"
                            placeholder="Enter your confirm password"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                confirmPassword: e.target.value,
                              })
                            }
                            defaultValue={formData?.confirmPassword}
                            maxLength={255}
                          />
                          <img
                            src={
                              !showConfirmPassword ? hidePassIcon : showPassIcon
                            }
                            height={"100%"}
                            width={"100%"}
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          />
                        </div>
                        {formErrors?.confirmPasswordError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.confirmPasswordError}
                          </p>
                        )}
                      </div>
                      <div className="mb-3 form-check term-and-conditions-outer">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="term-and-conditions"
                          onChange={(e) => {
                            setTermCondition(e.target.checked);
                            setIsDisabled(!e.target.checked);
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="term-and-conditions"
                        >
                          I agree to{" "}
                          <Link to={"/privacy-policy"}>
                            terms and privacy policy
                          </Link>
                        </label>
                      </div>
                      <div className="form-btn text-center">
                        <button
                          type="submit"
                          className="btn custom-details-btn login-btn"
                          onClick={() => handleSubmit()}
                          disabled={isDisabled}
                        >
                          SignUp
                        </button>
                      </div>
                      <p className="account-dec text-center">
                        If you have already an account!{" "}
                        <Link to="/login">Login</Link>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="login-devider position-relative my-5">
                  <hr />
                  <div className="more-option">
                    <span>Or</span>
                  </div>
                </div>
              </div>
              <div className="login-form-block">
                {configObj?.google?.google_client_id ? (
                  <LoginSocialGoogle
                    auto_select={false}
                    client_id={configObj?.google?.google_client_id}
                    onResolve={({ provider, data }) => {
                      manageSocialResponse({ provider, profile: data });
                    }}
                    cookiePolicy={"single_host_origin"}
                    scope={
                      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
                    }
                    onReject={(err) => {
                      console.log(err, "err===>");
                    }}
                  >
                    <div className="login-item google-login">
                      <img src={googleIcon} title="Google" />
                      <span>Google</span>
                    </div>
                  </LoginSocialGoogle>
                ) : (
                  <div
                    className="login-item google-login"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={googleIcon} title="Google" />
                    <span>Google</span>
                  </div>
                )}
                {configObj?.facebook?.facebook_client_id ? (
                  <LoginSocialFacebook
                    false
                    appId={configObj?.facebook?.facebook_client_id}
                    onResolve={({ provider, data }) => {
                      manageSocialResponse({ provider, profile: data });
                    }}
                    fields="email, picture"
                    scope="public_profile, email"
                    returnScopes={true}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <div className="login-item facebook-login">
                      <img src={faceBookIcon} title="Facebook" />
                      <span>Facebook</span>
                    </div>
                  </LoginSocialFacebook>
                ) : (
                  <div
                    className="login-item facebook-login"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={faceBookIcon} title="Coming Soon" />
                    <span>Facebook</span>
                  </div>
                )}
                {/* Linkedin login */}
                {configObj?.linkedin?.linkedin_client_id ? (
                  <LinkedInLogin
                    cliendID={configObj?.linkedin?.linkedin_client_id}
                    redirect_Url={`${WEB_URL}/dashboard/callback`}
                    scope={"openid,profile,email,w_member_social"}
                    callback={(type, res) => linkedinCallBack(type, res)}
                  >
                    <div className="login-item linkedIn-login">
                      <img src={linkedInIcon} title="LinkedIn" />
                      <span>LinkedIn</span>
                    </div>
                  </LinkedInLogin>
                ) : (
                  <div
                    className="login-item linkedIn-login"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={linkedInIcon} title="Coming Soon" />
                    <span>LinkedIn</span>
                  </div>
                )}

                {/* Twitter login */}
                <TwitterLogin
                  redirect_Url={`${WEB_URL}/dashboard/callback`}
                  callback={(type, res) => twitterCallBack(type, res)}
                >
                  <div className="login-item Twitter-login">
                    <img src={twitterIcon} title="Twitter(X)" />
                    <span>Twitter(X)</span>
                  </div>
                </TwitterLogin>
              </div>
            </div>
            {/* form outer */}
            <div className="adsense-box">
              <AdSenseAd
                client="ca-pub-4424794709901498"
                slot="3435754143"
                format="vertical"
                style={{ display: 'block', minWidth: "300px", maxWidth: "400px", width: "100%", height: "100%" }}
                responsive={true}
              />
            </div>
          </div>
        </div>
        {message && (
          <SuccessVerifyModal
            message={
              <>
                Your account is created successfully, Please <br /> check your
                email to verify your account.
              </>
            }
            btnTitle={'OK'}
            btnCallBack={(e) => handleGoLoginPage(e)}
          />
        )}
      </div>
    </div>
  );
};

export default SignUp;
