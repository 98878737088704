import axios from "axios";
import { API_URL } from "../../utils/config";
import {
  GET_ALL_SAVED_SNIPPETS,
  LOADING_SAVED_SNIPPETS,
  NOT_FOUND_SAVED_SNIPPETS,
} from "./type";
import { error } from "jquery";

export const getAllSavedSnippets = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING_SAVED_SNIPPETS,
    });
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        access_token: payload?.access_token,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      let reqURL = "/ocr/snippets";
      if (payload?.sort === "type") {
        reqURL += `?sort=type`;
      } else if(payload?.sort === "date"){
        reqURL += `?sort=date`;
      }
      
      const res = await axios.post(`${API_URL + reqURL}`, data, config);

      if (res?.data?.status === true) {
          if (res?.data?.data?.length <= 0) {
              dispatch({
                  type: NOT_FOUND_SAVED_SNIPPETS,
                  payload: null,
              });
          } else {
              dispatch({
                  type: GET_ALL_SAVED_SNIPPETS,
                  payload: res?.data,
              });
          }
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

// multiple delete snippets
export const multiRemoveSnippets = (payload, callBack) => {
  return async (dispatch) => {
    try {
      if (window.confirm("Are you sure?")) {
        let data = JSON.stringify({
          user_id: payload?.user_id,
          access_token: payload?.access_token,
          ids: payload?.ids,
        });
        let config = {
          maxBodyLength: Infinity,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        try {
          const res = await axios.delete(`${API_URL}/ocr/delete`, config);
          if (res?.data?.status === true) {
            callBack("success", res);
          }
        } catch (err) {
          let message = err?.response?.data;
          callBack("failed", message);
        }
      }
    } catch (err) {
      callBack("fail", "Something went wrong!");
    }
  };
};
