import React from 'react';

const Slider = React.forwardRef((props, ref) => {
    return (
        <div className='quix-dashboard-slider'>
            <div className='quix-dashboard-slider-outer'>
                <div id={props.sliderID} className={`carousel slide ${props.extraClass}`} ref={ref}>
                    <div className='quix-dashboard-slider-indicators'>{props.indicators}</div>
                    <div className="carousel-inner">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Slider;