import {
    LOADING_SAVED_SNIPPETS,
    GET_ALL_SAVED_SNIPPETS,
    NOT_FOUND_SAVED_SNIPPETS,
} from "../actions/type";

const INITIAL_STATE = {
    list: [],
    is_loading: false,
    not_found: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_SAVED_SNIPPETS:
            return { ...state, is_loading: true };
        case GET_ALL_SAVED_SNIPPETS:
            return { ...state, list: action.payload, is_loading: false };
        case NOT_FOUND_SAVED_SNIPPETS:
            return { ...state, not_found: true, is_loading: false };
        default:
            return state;
    }
};