import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import fbShareIcon from "../../assets/images/facebook-icon.png";
import linkedInShareIcon from "../../assets/images/linkedin-icon.png";
import twitterShareIcon from "../../assets/images/twitter-x-icon.png";
import evernoteShareIcon from "../../assets/images/icon-evernote.png";
import notionShareIcon from "../../assets/images/icon-notion.png";
import telegramShareIcon from "../../assets/images/icon-telegram.png";
import slackShareIcon from "../../assets/images/icon-slack.png";
import whatsAppShareIcon from "../../assets/images/icons-whatsapp.png";
import dropboxShareIcon from "../../assets/images/icon-dropbox.png";
import mediaShareIcon from "../../assets/images/icons-media-share.png";
import emailShareIcon from "../../assets/images/email-share-icon.png";
import qrcodeShareIcon from "../../assets/images/scan-qr-code-icon.png";
import youTubeShareIcon from "../../assets/images/quix-youtube-icon.png";
import googleDriveShareIcon from "../../assets/images/quix-google-drive-icon.png";
import teamShareIcon from "../../assets/images/icon-team.png";
import sharePointShareIcon from "../../assets/images/icon-sharepoints.png";
import oneNoteShareIcon from "../../assets/images/icon-one-note.png";
import ShareByEmail from "../../components/Modals/ShareByEmail";
import {
    checkServerURL,
    failureMessagePopup,
    shareOnMedia,
    successMessagePopup,
} from "../../helper/helper";
import { API_URL, localStoragePrefix, WEB_URL } from "../../utils/config";
import { manageVideoDetails } from "../../redux/actions/manageVideos";
import { manageScreenshotDetails } from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import { LoginSocialGoogle } from "reactjs-social-login";
import QrGenrateCard from "../../components/Modals/QrGenrateCard";
import { getGeneralSettingcredentials } from "../../redux/actions/configServices";
import TwitterLogin from "../../views/login/components/SocialLogin/TwitterLogin";
import { shareOnSocialMedia } from "../../redux/actions/social-share";
import LinkedInLogin from "../../views/login/components/SocialLogin/LinkedInLogin";
import NotionLogin from "../../views/login/components/SocialLogin/NotionLogin";
import DropBoxLogin from "../../views/login/components/SocialLogin/DropBoxLogin";
import ShareDetailsForm from "../../components/Modals/ShareDetailsForm";
import LoadingPage from "../../views/loading-page/LoadingPage";
import SlackLogin from "../../views/login/components/SocialLogin/SlackLogin";
import WhatsAppLogin from "../login/components/SocialLogin/WhatsAppLogin";
import TelegramLogin from "../login/components/SocialLogin/TelegramLogin";
import SlackChannelsList from "../../components/Modals/SlackChannelsList";
import { useParams, useSearchParams } from "react-router-dom";
import EvernoteLogin from "../login/components/SocialLogin/EvernoteLogin";
import MicroSoftLogin from "../login/components/SocialLogin/MicroSoftLogin";
import OneNoteList from "../../components/Modals/OneNoteList";
import { getUserDetailsByID } from "../../redux/actions/authentication";
import MediaCard from "./components/MediaCard";
import UpgradeModal from "../../components/Modals/UpgradeModal";

export const ShareMedia = () => {
    const { id, type } = useParams();
    const [searchParams] = useSearchParams();
    let token = searchParams.get("token");
    const itemID = id ?? "";
    const itemType = type ?? "";
    const dispatch = useDispatch();
    const [shareWithQr, setShareWithQr] = useState(false);
    const [showShareByEmail, setShareByEmailModal] = useState(false);
    const [shareDetailsObject, setShareDeatailsObject] = useState(null);
    const [configObj, setConfigObj] = useState({});
    const [isCopyText, setCopyText] = useState(false);
    const [showLoader, hideLoader] = useState(false);
    const [showUpgrageModal, setUpgrageModal] = useState(false);

    const userID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );

    const [userDetails, setUserDetails] = useState(null);
    useEffect(() => {
        if (!token) return;
        let authToken = token;
        dispatch(
            getUserDetailsByID(authToken, (res, type) => {
                if (type === "success") {
                    setUserDetails(res?.data);
                }
            })
        );
    }, []);

    const userPricingPlan =
        userDetails?.pricing_plan && userDetails?.pricing_plan;
    const userPlanFeatures =
        userPricingPlan?.features && JSON.parse(userPricingPlan?.features);
    const userIntegration =
        userPlanFeatures?.Integrations && userPlanFeatures?.Integrations;

    useEffect(() => {
        if (!itemID) return;
        const payload = {
            id: itemID,
        };
        if (itemType === "video") {
            dispatch(
                manageVideoDetails(payload, (type, res) => {
                    if (type === "success") {
                        setShareDeatailsObject(res);
                    }
                })
            );
        } else if (itemType === "image") {
            dispatch(
                manageScreenshotDetails(payload, (type, res) => {
                    if (type === "success") {
                        setShareDeatailsObject(res);
                    }
                })
            );
        }
    }, [itemID]);

    let itemPath = "";
    itemPath =
        itemType === "image"
            ? checkServerURL(API_URL, shareDetailsObject?.path)
            : checkServerURL(API_URL, shareDetailsObject?.modified_path);
    let staticItemPath =  `${WEB_URL}/${itemType === 'video' ? 'video' : 'screenshot'}/${itemID}`;

    useEffect(() => {
        getGeneralSettingcredentials((res) => {
            const googleDetails = res?.google && res?.google;
            const linkedinDetails = res?.linkedin && res?.linkedin;
            const facebook =
                res?.facebook && res?.facebook;
            const twitter =
                res?.twitter && res?.twitter;
            const dropbox = res?.dropbox && res?.dropbox;
            const notion = res?.notion && res?.notion;
            const slack = res?.slack && res?.slack;
            const evernote = res?.evernote && res?.evernote;
            const mail = res?.mail && res?.mail;
            const microsoft = res?.microsoft && res?.microsoft;

            setConfigObj({
                google: googleDetails,
                facebook: facebook,
                linkedin: linkedinDetails,
                twitter: twitter,
                dropbox: dropbox,
                notion: notion,
                slack: slack,
                microsoft: microsoft,
                evernote: evernote,
                mail: mail
            });
        });
    }, []);

    const shareViaSocialMedia = (type, URL) => {
        // Check if the sharing type is Facebook
        if (type === "facebook") {
            window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    URL
                )}`,
                "facebookpopup",
                "popup"
            );
        }
    };

    const handleUpload = (e) => {
        jQuery(".google-uploader-outer .share-on-media").removeClass("active");
        jQuery(e.currentTarget).addClass("active");
    };

    const handleResponse = async (response) => {
        const uploadType = jQuery(
            ".google-uploader-outer .share-on-media.active"
        ).attr("action-type");
        let authToken = response?.profile?.access_token;
        let scope = "";
        if (uploadType === "google") {
            scope = "https://www.googleapis.com/auth/drive.file";
        } else if (uploadType === "youtube") {
            scope = "https://www.googleapis.com/auth/youtube.upload";
        }
        const payload = {
            uploadType: uploadType,
            mediaType: itemType,
            id: itemID,
            authObject: {
                accessToken: `access_token=${authToken}&token_type=Bearer&expires_in=3599&scope=${scope}`,
            },
        };
        hideLoader(true);
        // Dispatch the upload action to upload the video
        dispatch(
            shareOnSocialMedia(payload, (type, res) => {
                if (type === "success") {
                    hideLoader(false);
                    successMessagePopup("Success Message!", "Upload successfully!");
                } else if (type === "failed") {
                    hideLoader(false);
                    failureMessagePopup("Error Message", "Something went wrong!");
                }
            })
        );
    };

    // Twitter callback function
    const twitterCallBack = (type, res) => {
        if (type === "success") {
            const data = res?.data;
            const payload = {
                uploadType: "twitter",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: data?.access_token,
                    accessSecret: data?.access_token_secret,
                    userId: data?.id,
                },
            };
            if (payload?.id !== "") {
                hideLoader(true);
                dispatch(
                    shareOnSocialMedia(payload, (type, res) => {
                        if (type === "success") {
                            successMessagePopup("Success Message!", "Upload successfully!");
                            hideLoader(false);
                        } else if (type === "failed") {
                            failureMessagePopup("Error Message", res?.message);
                            hideLoader(false);
                        }
                    })
                );
            } else {
                console.log("Information not found!");
            }
        } else if (type === "failed") {
            console.log("Something went wrong!");
        }
    };

    // Linkedin callback function
    const linkedinCallBack = async (type, res) => {
        if (type === "success") {
            const payload = {
                uploadType: "linkdin",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: res?.token,
                },
            };

            if (payload?.id !== "") {
                hideLoader(true);
                dispatch(
                    shareOnSocialMedia(payload, (type, res) => {
                        if (type === "success") {
                            successMessagePopup("Success Message!", "Upload successfully!");
                            hideLoader(false);
                        } else if (type === "failed") {
                            failureMessagePopup("Error Message", res?.message);
                            hideLoader(false);
                        }
                    })
                );
            } else {
                console.log("Information not found!");
            }
        } else if (type === "failed") {
            console.log("Something went wrong!");
        }
    };

    const [notionDetailModal, setNotionDetailModal] = useState(false);
    const [notionDetailCallbackData, setNotionCallbackData] = useState(null);

    const notionDetailsCallback = (res) => {
        setNotionDetailModal(false);
        if (notionDetailCallbackData !== null) {
            hideLoader(true);
            const dataObj = notionDetailCallbackData;
            const formDatails = res;

            const payload = {
                uploadType: "notion",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: dataObj?.data?.token,
                    databaseId: dataObj?.data?.note?.databases?.results?.[0]?.id,
                    title: formDatails?.title,
                    description: formDatails?.description,
                },
            };

            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message!", "Upload successfully!");
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong, please try different sharing options.");
                        hideLoader(false);
                    }
                })
            );
        }
    };
    // Notion callback function
    const notionCallBack = (type, res) => {
        if (type === "success") {
            setNotionDetailModal(true);
            setNotionCallbackData(res);
        } else {
            setNotionCallbackData(null);
        }
    };

    // DropBox callback function
    const dropBoxCallBack = (type, res) => {
        if (type === "success") {
            hideLoader(true);
            const payload = {
                uploadType: "dropbox",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: res?.data?.accessToken,
                },
            };
            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message!", "Upload successfully!");
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong!");
                        hideLoader(false);
                    }
                })
            );
        } else {
            console.log(res, "Something went wrong!");
        }
    };

    const [slackListModal, setSlackListModal] = useState(false);
    const [slackListCallbackData, setSlackListCallbackData] = useState();

    const slackCallBack = (type, res) => {
        if (type === "success") {
            setSlackListModal(true);
            setSlackListCallbackData(res);
        } else {
            setSlackListModal(false);
            setNotionCallbackData(null);
        }
    };

    const handleSlackCallbackList = (res) => {
        hideLoader(true);
        setSlackListModal(false);
        const payload = {
            uploadType: "slack",
            mediaType: itemType,
            id: itemID,
            authObject: {
                accessToken: res?.token,
                channel: res?.channel,
            },
        };
        dispatch(
            shareOnSocialMedia(payload, (type, res) => {
                if (type === "success") {
                    successMessagePopup("Success Message!", "Upload successfully!");
                    hideLoader(false);
                } else if (type === "failed") {
                    failureMessagePopup("Error Message", "Something went wrong!");
                    hideLoader(false);
                }
            })
        );
    };

    const [evernoteDetailModal, setEvernoteDetailModal] = useState(false);
    const [evernoteDetailCallbackData, setEvernoteCallbackData] = useState(null);
    const evernoteDetailsCallback = (res) => {
        setEvernoteDetailModal(false);
        if (evernoteDetailCallbackData !== null) {
            hideLoader(true);
            const dataObj = evernoteDetailCallbackData;
            const formDatails = res;
            const payload = {
                uploadType: "evernote",
                mediaType: itemType,
                id: itemID,
                authObject: {
                    accessToken: dataObj?.accessToken,
                    title: formDatails?.title,
                    description: formDatails?.description,
                },
            };
            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup(
                            "Success Message!",
                            "Evernote Upload successfully!"
                        );
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong!");
                        hideLoader(false);
                    }
                })
            );
        }
    };
    const evernoteCallBack = (type, res) => {
        if (type === "success") {
            setEvernoteDetailModal(true);
            setEvernoteCallbackData(res);
        } else {
            setEvernoteDetailModal(false);
            setEvernoteCallbackData(null);
        }
    };

    const [onenoteDetailModal, setOnenoteDetailModal] = useState(false);
    const [onenoteDetailCallbackData, setOnenoteCallbackData] = useState(null);
    const microsoftCallBack = (type, res) => {
        if (type === "success") {
            setOnenoteDetailModal(true);
            setOnenoteCallbackData(res);
        } else {
            setOnenoteDetailModal(false);
            setOnenoteCallbackData(null);
        }
    };

    const handleOnenoteCallbackList = (res) => {
        hideLoader(true);
        setOnenoteDetailModal(false);
        if (onenoteDetailCallbackData?.accessToken) {
            const payload = {
                uploadType: "onenote",
                mediaType: itemType,
                vid: itemID,
                authObject: {
                    accessToken: onenoteDetailCallbackData?.accessToken,
                    notebookId: res?.channel,
                },
            };
            dispatch(
                shareOnSocialMedia(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success Message!", "Upload successfully!");
                        hideLoader(false);
                    } else if (type === "failed") {
                        failureMessagePopup("Error Message", "Something went wrong!");
                        hideLoader(false);
                    }
                })
            );
        }
    };

    const handleCopy = (itemPath) => {
        if (isCopyText) return;
        navigator.clipboard
            .writeText(itemPath)
            .then(() => {
                setCopyText(true);
                setTimeout(() => {
                    setCopyText(false);
                }, 2000);
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
                setCopyText(false);
            });
    };

    return (
        <div>
            <div className="social-share-page card border-0">
                <div className="social-share-main-heading text-center">
                    <h5>Share with Anyone</h5>
                </div>
                {showLoader && <LoadingPage opacity={"0.6"} />}
                <div className="card-body">
                    <div className="socials-media-options text-center  mt-3">
                        <div className="socials-media-platforms">
                            {/* FaceBook */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.facebook?.facebook_status}
                                mediaIcon={fbShareIcon}
                                className={'share-on-media share-facebook'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.facebook ? (
                                    <div className="quix-social-share-form">
                                        <MediaCard
                                            mediaIcon={fbShareIcon}
                                            onClick={() => shareViaSocialMedia("facebook", itemPath)}
                                            className={"share-on-media share-facebook"}
                                            title={"Facebook"}
                                        />
                                    </div>
                                ) : (
                                    <MediaCard
                                        mediaIcon={fbShareIcon}
                                        className={"share-on-media share-facebook"}
                                        style={{ opacity: "0.5" }}
                                        title={"Facebook"}
                                        isPaid={userIntegration?.integration_media?.facebook}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* LinkedIn */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.linkedin?.linkedin_status}
                                mediaIcon={linkedInShareIcon}
                                className={'share-on-media share-linkedIn'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.linkedin ? (
                                    <LinkedInLogin
                                        cliendID={configObj?.linkedin?.linkedin_client_id}
                                        redirect_Url={`${WEB_URL}/dashboard/callback`}
                                        scope={"openid,profile,email,w_member_social"}
                                        callback={(type, res) => linkedinCallBack(type, res)}
                                    >
                                        <MediaCard
                                            mediaIcon={linkedInShareIcon}
                                            className={"share-on-media share-linkedIn"}
                                            title={"Linkedin"}
                                        />
                                    </LinkedInLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={linkedInShareIcon}
                                        className={"share-on-media share-linkedIn"}
                                        style={{ opacity: "0.5" }}
                                        title={"linkedIn"}
                                        isPaid={userIntegration?.integration_media?.linkedin}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Twitter */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.twitter?.twitter_status}
                                mediaIcon={twitterShareIcon}
                                className={'share-on-media share-Twitter'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.twitter ? (
                                    <TwitterLogin
                                        redirect_Url={`${WEB_URL}/dashboard/callback`}
                                        callback={(type, res) => twitterCallBack(type, res)}
                                    >
                                        <MediaCard
                                            mediaIcon={twitterShareIcon}
                                            className={"share-on-media share-Twitter"}
                                            title={"Twitter(X)"}
                                        />
                                    </TwitterLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={twitterShareIcon}
                                        className={"share-on-media share-Twitter"}
                                        style={{ opacity: "0.5" }}
                                        title={"Twitter(X)"}
                                        isPaid={userIntegration?.integration_media?.twitter}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* WhatsApp */}
                            {<CheckIsAvalibleMedia
                                isAvalible={true} //configObj?.whats_app?.whats_app_status
                                mediaIcon={whatsAppShareIcon}
                                className={'share-on-media share-whatsapp'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.whats_app ? (
                                    <WhatsAppLogin URL={itemPath}>
                                        <MediaCard
                                            mediaIcon={whatsAppShareIcon}
                                            className={"share-on-media share-whatsapp"}
                                            title={"WhatsApp"}
                                        />
                                    </WhatsAppLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={whatsAppShareIcon}
                                        className={"share-on-media share-whatsapp"}
                                        style={{ opacity: "0.5" }}
                                        title={"WhatsApp"}
                                        isPaid={userIntegration?.integration_media?.whats_app}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Telegram */}
                            {<CheckIsAvalibleMedia
                                isAvalible={true} //configObj?.telegram?.telegram_status
                                mediaIcon={telegramShareIcon}
                                className={'share-on-media share-telegram'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.telegram ? (
                                    <TelegramLogin URL={itemPath}>
                                        <MediaCard
                                            mediaIcon={telegramShareIcon}
                                            className={"share-on-media share-telegram"}
                                            title={"Telegram"}
                                        />
                                    </TelegramLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={telegramShareIcon}
                                        className={"share-on-media share-telegram"}
                                        style={{ opacity: "0.5" }}
                                        title={"Telegram"}
                                        isPaid={userIntegration?.integration_media?.telegram}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Evernote */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.evernote?.evernote_status}
                                mediaIcon={evernoteShareIcon}
                                className={'share-on-media share-evernote'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.evernote ? (
                                    <EvernoteLogin
                                        redirect_Url={`${WEB_URL}/dashboard/callback`}
                                        callback={(type, res) => evernoteCallBack(type, res)}
                                    >
                                        <MediaCard
                                            mediaIcon={evernoteShareIcon}
                                            className={"share-on-media share-evernote"}
                                            title={"Evernote"}
                                        />
                                    </EvernoteLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={evernoteShareIcon}
                                        className={"share-on-media share-evernote"}
                                        style={{ opacity: "0.5" }}
                                        title={"Evernote"}
                                        isPaid={userIntegration?.integration_media?.evernote}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* DropBox */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.dropbox?.dropbox_status}
                                mediaIcon={dropboxShareIcon}
                                className={'share-on-media share-DropBox'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.dropbox ? (
                                    <DropBoxLogin
                                        redirect_Url={`${WEB_URL}/dashboard/callback`}
                                        apiKey={configObj?.dropbox?.dropbox_api_key}
                                        callback={(type, res) => dropBoxCallBack(type, res)}
                                    >
                                        <MediaCard
                                            mediaIcon={dropboxShareIcon}
                                            className={"share-on-media share-DropBox"}
                                            title={"Dropbox"}
                                        />
                                    </DropBoxLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={dropboxShareIcon}
                                        className={"share-on-media share-DropBox"}
                                        style={{ opacity: "0.5" }}
                                        title={"Dropbox"}
                                        isPaid={userIntegration?.integration_media?.dropbox}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Notion */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.notion?.notion_status}
                                mediaIcon={notionShareIcon}
                                className={'share-on-media share-Notion'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.notion ? (
                                    <NotionLogin
                                        cliendID={configObj?.notion?.notion_client_id}
                                        callback={(type, res) => notionCallBack(type, res)}
                                    >
                                        <MediaCard
                                            mediaIcon={notionShareIcon}
                                            className={"share-on-media share-Notion"}
                                            title={"Notion"}
                                        />
                                    </NotionLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={notionShareIcon}
                                        className={"share-on-media share-Notion"}
                                        style={{ opacity: "0.5" }}
                                        title={"Notion"}
                                        isPaid={userIntegration?.integration_media?.notion}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Slack */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.slack?.slack_status}
                                mediaIcon={slackShareIcon}
                                className={'share-on-media share-slack'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.slack ? (
                                    <SlackLogin
                                        callback={(type, res) => slackCallBack(type, res)}
                                        clientId={configObj?.slack?.slack_client_id}
                                        redirect_Url={`${WEB_URL}/dashboard/callback`}
                                    >
                                        <MediaCard
                                            mediaIcon={slackShareIcon}
                                            className={"share-on-media share-slack"}
                                            title={"Slack"}
                                        />
                                    </SlackLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={slackShareIcon}
                                        className={"share-on-media share-slack"}
                                        style={{ opacity: "0.5" }}
                                        title={"Slack"}
                                        isPaid={userIntegration?.integration_media?.slack}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {itemType === "image" && (userIntegration?.status ? (
                                <LoginSocialGoogle
                                    false
                                    client_id={configObj?.google?.google_client_id}
                                    onResolve={({ provider, data }) => {
                                        handleResponse({ provider, profile: data });
                                    }}
                                    cookiePolicy={"single_host_origin"}
                                    scope={
                                        "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/youtube.upload"
                                    }
                                    isOnlyGetToken={true}
                                    onReject={(err) => {
                                        console.log(err);
                                    }}
                                >
                                    <div className="google-uploader-outer">
                                        {userIntegration?.status && userIntegration?.integration_media?.drive && (
                                            <MediaCard
                                                mediaIcon={googleDriveShareIcon}
                                                onClick={(e) => handleUpload(e)}
                                                className={"share-on-media google-uploader"}
                                                title={"Drive"}
                                                actionType={"google"}
                                            />
                                        )}
                                    </div>
                                </LoginSocialGoogle>
                            ) : (
                                !userIntegration?.integration_media?.drive && (
                                    <div className="google-uploader-outer">
                                        <MediaCard
                                            mediaIcon={googleDriveShareIcon}
                                            className={"share-on-media google-uploader"}
                                            title={"Coming Soon"}
                                            style={{ opacity: "0.5" }}
                                        />
                                    </div>
                                )
                            ))}
                            {itemType === "video" && (userIntegration?.status ? (
                                <LoginSocialGoogle
                                    false
                                    client_id={configObj?.google?.google_client_id}
                                    onResolve={({ provider, data }) => {
                                        handleResponse({ provider, profile: data });
                                    }}
                                    cookiePolicy={"single_host_origin"}
                                    scope={
                                        "https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/youtube.upload"
                                    }
                                    isOnlyGetToken={true}
                                    onReject={(err) => {
                                        console.log(err);
                                    }}
                                >
                                    <div className="google-uploader-outer">
                                        {userIntegration?.integration_media?.drive ? (
                                            <MediaCard
                                                mediaIcon={googleDriveShareIcon}
                                                onClick={(e) => handleUpload(e)}
                                                className={"share-on-media google-uploader"}
                                                title={"Drive"}
                                                actionType={"google"}
                                            />
                                        ) : (<MediaCard
                                            mediaIcon={googleDriveShareIcon}
                                            className={"share-on-media google-uploader"}
                                            title={"Coming Soon"}
                                            style={{ opacity: "0.5" }}
                                        />)}
                                        {userIntegration?.integration_media?.youtube ? (
                                            <MediaCard
                                                mediaIcon={youTubeShareIcon}
                                                onClick={(e) => handleUpload(e)}
                                                className={"share-on-media google-uploader"}
                                                title={"Youtube"}
                                                actionType={"youtube"}
                                            />
                                        ) : (
                                            <MediaCard
                                                mediaIcon={youTubeShareIcon}
                                                className={"share-on-media google-uploader"}
                                                title={"Coming Soon"}
                                                style={{ opacity: "0.5" }}
                                            />
                                        )}
                                    </div>
                                </LoginSocialGoogle>
                            ) : (
                                !userIntegration?.integration_media?.drive || !userIntegration?.integration_media?.youtube && (
                                    <div className="google-uploader-outer">
                                        {!userIntegration?.integration_media?.drive && (
                                            <MediaCard
                                                mediaIcon={googleDriveShareIcon}
                                                className={"share-on-media google-uploader"}
                                                title={"Coming Soon"}
                                                style={{ opacity: "0.5" }}
                                            />
                                        )}
                                        {!userIntegration?.integration_media?.youtube && (
                                            <MediaCard
                                                mediaIcon={youTubeShareIcon}
                                                className={"share-on-media google-uploader"}
                                                title={"Coming Soon"}
                                                style={{ opacity: "0.5" }}
                                            />
                                        )}
                                    </div>
                                )

                            ))}
                            {/* Team */}
                            {<CheckIsAvalibleMedia
                                isAvalible={false} //configObj?.microsoft?.microsoftazure_status
                                mediaIcon={teamShareIcon}
                                className={'share-on-media share-team'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.team ? (
                                    <MediaCard
                                        mediaIcon={teamShareIcon}
                                        className={"share-on-media share-team"}
                                        title={"Team"}
                                    />
                                ) : (
                                    <MediaCard
                                        mediaIcon={teamShareIcon}
                                        className={"share-on-media share-team"}
                                        style={{ opacity: "0.5" }}
                                        title={"Team"}
                                        isPaid={userIntegration?.integration_media?.team}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Share Point */}
                            {<CheckIsAvalibleMedia
                                isAvalible={false}//configObj?.microsoft?.microsoftazure_status
                                mediaIcon={sharePointShareIcon}
                                className={'share-on-media share-Sharepoint'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.share_point ? (
                                    <MediaCard
                                        mediaIcon={sharePointShareIcon}
                                        className={"share-on-media share-Sharepoint"}
                                        title={"Share Point"}
                                    />
                                ) : (
                                    <MediaCard
                                        mediaIcon={sharePointShareIcon}
                                        className={"share-on-media share-Sharepoint"}
                                        style={{ opacity: "0.5" }}
                                        title={"Share Point"}
                                        isPaid={userIntegration?.integration_media?.share_point}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* OneNote */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.microsoft?.microsoftazure_status}
                                mediaIcon={oneNoteShareIcon}
                                className={'share-on-media share-Onenote'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.onenote ? (
                                    <MicroSoftLogin
                                        clientId={configObj?.microsoft?.microsoftazure_client_id}
                                        redirect_Url={`${WEB_URL}/dashboard/callback`}
                                        callback={(type, res) => microsoftCallBack(type, res)}
                                    >
                                        <MediaCard
                                            mediaIcon={oneNoteShareIcon}
                                            className={"share-on-media share-Onenote"}
                                            title={"OneNote"}
                                        />
                                    </MicroSoftLogin>
                                ) : (
                                    <MediaCard
                                        mediaIcon={oneNoteShareIcon}
                                        className={"share-on-media share-Onenote"}
                                        style={{ opacity: "0.5" }}
                                        title={"OneNote"}
                                        isPaid={userIntegration?.integration_media?.onenote}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Email */}
                            {<CheckIsAvalibleMedia
                                isAvalible={configObj?.mail?.mail_status}
                                mediaIcon={emailShareIcon}
                                className={'share-on-media share-email'}
                            >
                                {userIntegration?.status && userIntegration?.integration_media?.email ? (
                                    <MediaCard
                                        mediaIcon={emailShareIcon}
                                        onClick={() => setShareByEmailModal(!showShareByEmail)}
                                        className={"share-on-media share-email"}
                                        title={"Email"}
                                    />
                                ) : (
                                    <MediaCard
                                        mediaIcon={emailShareIcon}
                                        className={"share-on-media share-email"}
                                        style={{ opacity: "0.5" }}
                                        title={"Email"}
                                        isPaid={userIntegration?.integration_media?.email}
                                        onClick={() => setUpgrageModal(!showUpgrageModal)}
                                    />
                                )}
                            </CheckIsAvalibleMedia>}
                            {/* Qr Code */}
                            {userIntegration?.status && userIntegration?.integration_media?.qr_code ? (
                                <MediaCard
                                    mediaIcon={qrcodeShareIcon}
                                    onClick={() => setShareWithQr(true)}
                                    className={"share-on-media share-qr-code"}
                                    title={"QR Code"}
                                />
                            ) : (
                                <MediaCard
                                    mediaIcon={qrcodeShareIcon}
                                    className={"share-on-media share-email"}
                                    style={{ opacity: "0.5" }}
                                    title={"Coming Soon"}
                                />
                            )}
                            {/* 3rd party Applications */}
                            {userIntegration?.status && userIntegration?.integration_media?.qr_code ? (
                                <MediaCard
                                    mediaIcon={mediaShareIcon}
                                    onClick={() => shareOnMedia(staticItemPath)}
                                    className={"share-on-media share-qr-code"}
                                    title={"3rd party Applications"}
                                />
                            ) : (
                                <MediaCard
                                    mediaIcon={mediaShareIcon}
                                    className={"share-on-media share-email"}
                                    style={{ opacity: "0.5" }}
                                    title={"Coming Soon"}
                                />
                            )}
                        </div>
                    </div>
                    <div className="social-share-with-link mt-4">
                        <div className="title-option text-center">
                            <h6>Copy and Share Link</h6>
                        </div>
                        <div className="quix-email-share-form d-flex gap-1">
                            <input
                                defaultValue={staticItemPath}
                                type="text"
                                name="share-Link"
                                id="share-screenshot-link"
                                className="form-control rounded-2"
                                maxLength={255}
                            />
                            <button
                                className="send-link-share"
                                onClick={() => handleCopy(staticItemPath)}
                            >
                                {isCopyText ? "Copied" : "Copy"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {slackListModal && (
                <SlackChannelsList
                    closeModal={setSlackListModal}
                    ChannelsList={slackListCallbackData}
                    callback={(e) => handleSlackCallbackList(e)}
                />
            )}
            {notionDetailModal && (
                <ShareDetailsForm
                    closeModal={setNotionDetailModal}
                    data={shareDetailsObject}
                    callback={(e) => notionDetailsCallback(e)}
                />
            )}
            {shareWithQr && (
                <QrGenrateCard
                    showModal={shareWithQr}
                    closeModal={setShareWithQr}
                    path={staticItemPath}
                />
            )}
            {showShareByEmail && (
                <ShareByEmail
                    showModal={showShareByEmail}
                    closeModal={setShareByEmailModal}
                    itemType={itemType}
                    itemID={itemID}
                    userID={userID}
                />
            )}
            {evernoteDetailModal && (
                <ShareDetailsForm
                    showModal={evernoteDetailModal}
                    closeModal={setEvernoteDetailModal}
                    data={shareDetailsObject}
                    callback={(e) => evernoteDetailsCallback(e)}
                />
            )}
            {onenoteDetailModal && (
                <OneNoteList
                    ChannelsList={onenoteDetailCallbackData}
                    callback={(e) => handleOnenoteCallbackList(e)}
                    closeModal={setOnenoteDetailModal}
                />
            )}
            {showUpgrageModal && (
                <UpgradeModal showModal={showUpgrageModal} closeModal={setUpgrageModal} />
            )}
        </div>
    );
};

ShareMedia.dafaultProps = {
    closeModal: function () { },
};

const CheckIsAvalibleMedia = ({ children, isAvalible, mediaIcon, className }) => {
    if (isAvalible) {
        return <>{children}</>
    } else {
        return (
            <MediaCard
                mediaIcon={mediaIcon}
                className={className}
                style={{ opacity: "0.5" }}
                title={"Coming Soon"}
            />
        )
    }
}
