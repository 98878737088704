import axios from "axios";
import { API_URL } from "../../../utils/config";
import {
    LOADING_EXTENSION,
    GET_EXTENSION_DETAILS,
    UPDATE_EXTENSION_SETTING,
} from "../type";

// get extension details
export const getExtensionDetailsAdmin = (adminID) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_EXTENSION,
            });

            let data = JSON.stringify({
                admin_id: adminID,
            });

            let config = {
                maxBodyLength: Infinity,
                headers: {
                    "Content-Type": "application/json",
                },
            };

            const res = await axios.post(
                `${API_URL}/admin/extension-settings/list`,
                data,
                config
            );

            if (res?.data?.status === true) {
                dispatch({
                    type: GET_EXTENSION_DETAILS,
                    payload: res?.data,
                });
            }
        } catch (error) {
            console.log(error, "---error---");
        }
    };
};

// update extension settings
export const updateExtensionSettingsAdmin = (payload, callBack) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: LOADING_EXTENSION,
            });
            let data = new FormData();
            data.append("admin_id", payload?.admin_id);
            data.append("extension_name", payload?.extension_name);
            data.append("extension_logo", payload?.logo);
            data.append("logo", payload?.extra_logo);
            data.append("powered_by_logo", payload?.poweredLogo);
            data.append("ocr_capture", JSON.stringify(payload?.OCR_capture));
            data.append("capture", JSON.stringify(payload?.capture));
            data.append("recorder", JSON.stringify(payload?.recorder));
            data.append("extension_ui", JSON.stringify(payload?.extension_ui));

            let config = {
                maxBodyLength: Infinity,
            };
            const res = await axios.post(
                `${API_URL}/admin/extension-settings/add-extension-setting`,
                data,
                config
            );
            if (res?.data?.status === true) {
                dispatch({
                    type: UPDATE_EXTENSION_SETTING,
                    payload: res?.data,
                });
                callBack("success", res?.data);
            }
        } catch (error) {
            let message = error?.response?.data;
            callBack("failed", message);
            console.log(error, "--error--");
        }
    };
};
